import { Modal } from "./js/modal.js";
import { Carousel } from "./js/carousel.js";
import { Validate } from "./js/validate.js";
import { Select } from "./js/select.js";

(function (app) {
  "use strict";

  window.app = app;

  app.init = function () {
    app.body = document.body;
    app.html = document.querySelector("html");
    app.menu();
    setTimeout(() => {
      app.carousels();
    }, 100);
    app.forms();
    app.buttonEffect();
    app.anim();
  };

  app.modal = Modal();

  app.spinner = {
    show: function (container) {
      let layer = document.createElement("div");
      let spinner = document.createElement("div");
      container.classList.add("loading");
      layer.classList.add("loading-layer");
      spinner.classList.add("sp-wave");
      layer.appendChild(spinner);
      container.appendChild(layer);
    },
    hide: function (container) {
      let layer = container.querySelector(".loading-layer");
      container.classList.remove("loading");
      if (layer) {
        layer.remove();
      }
    },
  };

  app.isTouchDevice = function () {
    return "ontouchstart" in window;
  };

  app.isMobile = function (s) {
    const size = s ? s : 1024;
    return (
      window.matchMedia("only screen and (max-width: " + size + "px)")
        .matches && app.isTouchDevice()
    );
  };

  app.anim = function () {
    document.querySelectorAll(".parallax-anim svg").forEach((svg) => {
      svg.querySelectorAll(".parallax-layer").forEach((layer) => {
        //console.log(layer);
        let clone = layer.cloneNode(true);
        clone.classList.add("clone");
        layer.after(clone);
      });
    });
  };

  app.buttonEffect = function () {
    const buttons = document.querySelectorAll(".button");
    buttons.forEach(function (button) {
      button.addEventListener("click", function (event) {
        const button = event.currentTarget;

        const circle = document.createElement("em");
        const diameter = Math.max(button.offsetWidth, button.offsetHeight);
        const radius = diameter / 2;

        circle.style.width = circle.style.height = diameter + "px";
        circle.style.left = event.offsetX - radius + "px";
        circle.style.top = event.offsetY - radius + "px";
        circle.classList.add("ripple");

        button.querySelectorAll(".ripple").forEach(function (ripple) {
          ripple.remove();
        });

        button.appendChild(circle);
      });
    });
  };

  app.menu = function () {
    const header = document.querySelector(".site-header");
    if (!header) return;
    let y = 0;
    let prevy = 0;
    let up = false;
    app.top = 0;
    let checkHeader = function (e) {
      y = window.scrollY;
      up = y - prevy < 0;
      // app.top = app.isMobile(500) ? 450 : window.innerHeight - 100;
      app.top = Math.max(header.offsetHeight - 90, app.top);
      app.miniheader = window.scrollY >= app.top;
      // header.classList[up && y !== 0 ? "add" : "remove"]("background");
      // header.classList[up ? "remove" : "add"]("mini");
      if (!header.classList.contains("fix")) {
        header.classList[app.miniheader ? "add" : "remove"]("mini");
      }
      prevy = y;
    };
    window.addEventListener("scroll", checkHeader);
    window.addEventListener("resize", () => {
      app.top = document.querySelector(".site-header").offsetHeight - 90;
    });
    checkHeader();
  };

  app.carousels = function () {
    let mediaCarousels = document.querySelectorAll(
      '[data-component="media-carousel"]'
    );

    mediaCarousels.forEach(function (mediaCarousel) {
      let active = document.querySelector(
        location.hash
          ? "[data-id='" + location.hash.replace("#", "") + "']"
          : "[data-id].active"
      );
      //console.log(mediaCarousel);
      if (!active) {
        //return;
      }
      let index = active
        ? Array.prototype.indexOf.call(active.parentElement.children, active) +
        1
        : 2;
      //console.log(active, index)
      Carousel(mediaCarousel, {
        desktopSwipe: true,
        infinite: true,
        clones: 2,
        index: index,
        click: function (item) {
          let hash = item.href.split("#")[1];
          let active = document.querySelector("[data-id=" + hash + "]");

          var offset = active.getBoundingClientRect();
          window.scrollTo({
            top: app.top,
            left: 0,
            behavior: "smooth",
          });
          //console.log(offset.top, active);
        },
        active: function (item) {
          let hash = item.href.split("#")[1];
          let active = document.querySelector("[data-id=" + hash + "]");
          if (active) {
            active.parentElement
              .querySelectorAll("section.content")
              .forEach((sibling) => {
                sibling.classList.remove("active");
              });
            active.classList.add("active");
          }
          // console.log("CHANGE ", app.miniheader);
          //if (app.miniheader /*app.isMobile(500)*/) {
          // setTimeout(() => {
          location.hash = hash;
          // }, 100);
          //}
        },
      });
    });
  };

  app.forms = function () {
    /* let formAlert = document.querySelector(".alert");
    if (formAlert) {
      formAlert.remove();
      app.modal.open({
        title: formAlert.classList.contains("alert-success")
          ? "Sikeres Jelentkezés!"
          : "Valami nagyon NOT GOOD",
        content: formAlert.innerText,
        buttons: [
          {
            text: "Rendben",
            action: function () {},
          },
        ],
      });
    } */

    let warn = function (e, scrolling) {
      let form = document.querySelector(".active form");
      if (!form) return
      let rect = form.getBoundingClientRect();
      if (
        ((scrolling && rect.y > 1000) || !scrolling) &&
        form.changed &&
        !form.approveWarning
      ) {
        app.modal.open({
          title: "Valóban befejezed az űrlap kitöltését?",
          class: "default",
          content: "",
          buttons: [
            {
              text: '<svg width="73px" height="72px" version="1.1" viewBox="0 0 4.51 4.45" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><rect class="svgbg" fill="none" stroke="#FEFEFE" stroke-width="0.19" stroke-miterlimit="22.9256" x=".09" y=".09" width="4.32" height="4.26" rx="2.16" ry="2.13"/><path fill="#fefefe" d="m1.69 2.74 0.49-0.5v0.46l-0.67 0.67-0.67-0.67v-0.46l0.49 0.5v-0.96c0-0.51 0.42-0.93 0.94-0.93 0.51 0 0.93 0.42 0.93 0.93v1.6h-0.35v-1.6c0-0.31-0.26-0.57-0.58-0.57s-0.58 0.26-0.58 0.57v0.96z"/></svg>',
              action: function () {
                let form = document.querySelector(".active form");
                let id = form.parentNode.querySelector(".anchor").id;
                form.approveWarning = true;
                window.location.hash = "#";
                window.location.hash = id;
              },
            },
            {
              text: '<svg width="73px" height="72px" version="1.1" viewBox="0 0 3.93 3.88" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><rect class="svgbg" fill="none" x=".08" y=".08" width="3.77" height="3.72" rx=".4" ry=".39" stroke="#FEFEFE" stroke-width="0.16" stroke-miterlimit="22.9256" /><polygon fill="#fefefe" points="2.37 1.31 1.76 1.31 2.04 1.03 2.59 1.03 2.87 1.03 2.87 1.03 2.87 1.31 2.87 1.86 2.59 2.14 2.59 1.53 1.68 2.44 1.68 3.37 1.37 3.37 1.37 0.51 1.68 0.51 1.68 2"/></svg>',
              action: function () {
                let form = document.querySelector(".active form");
                form.approveWarning = true;
              },
            },
          ],
        });
      }
    };

    document.querySelectorAll(".select").forEach(function (sel) {
      let select = new Select(
        sel,
        [
          {
            value: "dunakeszi",
            title: "Dunakeszi",
          },
          {
            value: "szikszo",
            title: "Szikszó",
          },
          {
            value: "debrecen",
            title: "Debrecen",
          },
        ],
        function (item) {
          console.log("Select: ", item);
        }
      );
      select.scroll.update();
    });

    window.addEventListener(
      "beforeunload",
      (e) => {
        console.log("beforeunload");
        return false;
      },
      { capture: true }
    );

    window.addEventListener("scroll", (e) => {
      warn(e, true);
    });
    // document.addEventListener("mouseleave", warn);

    let formContainers = document.querySelectorAll('[data-component="form"]');

    formContainers.forEach(function (formContainer) {
      let form = formContainer.querySelector("form");
      let inputs = form.querySelectorAll("input, textarea");
      let submit = form.querySelector("button[type=submit]");

      form.changed = false;

      inputs.forEach((inp) => {
        inp.addEventListener("change", () => {
          form.changed = true;
        });
        inp.addEventListener("input", () => {
          form.changed = true;
        });
      });

      /*let callback = (entries, observer) => {
        entries.forEach((entry) => {
          console.log("int: ", entry.isIntersecting);
        });
      };
      let observer = new IntersectionObserver(callback, {
        threshold: 1.0,
      });

      observer.observe(form);*/

      Validate(form, {
        submit: handleSubmit,
        messages: {
          valueMissing: "Kötelező mező!",
          typeMismatch: "Nem megfelelő típus!",
          badInput: "Hibás érték!",
          patternMismatch: "Nem megfelelő formátum!",
          rangeOverflow: "Tartományon kívül esik!",
          tooLong: "Az érték túl hosszú!",
          tooShort: "Az érték túl rövid",
        },
      });

      //form.addEventListener("submit", handleSubmit);

      function handleSubmit(e) {
        let url = "https://api.bhstrans.hu/v1/job/subscription";
        const postData = {
          "host": "gepjarmuvezetok",
          "data": {
            "form": {},
            "files": {}
          }
        }
        let formData = new FormData(form);
        //formData.set("answer", "sofor" + "allas");
        inputs.forEach(function (input) {
          input.disabled = true;
        });
        submit.disabled = true;
        postData.data.form = Object.fromEntries(formData)
        app.spinner.show(formContainer);
        fetch(url, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': app.token,
          },
          body: JSON.stringify(postData),
        })
          .then((res) => {
            // console.log("then", res);
            app.spinner.hide(formContainer);
            inputs.forEach(function (input) {
              input.disabled = false;
            });
            submit.disabled = false;
            return res.json();
          })
          .then((response) => {
            // console.log(response.error);
            if (response.message && !response.error) {
              app.modal.open({
                title: "SIKERES<br>JELENKEZÉS!",
                class: "success",
                content:
                  '<strong>Kollégáink hamarosan<br>felveszik veled a kapcsolatot!</strong><svg width="120px" height="125px" version="1.1" viewBox="0 0 45.39 47.08" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><defs><style type="text/css"><![CDATA[ .str0 {stroke:#2B2A29;stroke-width:1.13;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.9256} .fil1 {fill:none} .fil0 {fill:#FEFEFE} ]]></style></defs><path class="fil0" d="m33.75 0c6.43 0 11.64 4.47 11.64 9.99 0 5.51-5.21 9.99-11.64 9.99-0.42 0-0.83-0.02-1.23-0.06-1.18 2.85-3.82 5.11-7.16 6.1 1.31-1.56 2.25-4.25 2.5-7.41-3.44-1.74-5.75-4.95-5.75-8.62 0-5.52 5.21-9.99 11.64-9.99z"/><path class="fil1 str0" d="m23.55 40.45c1.9 0.68 3.53 1.28 5.12 2.3"/><path class="fil1 str0" d="m14.32 40.44c-1.9 0.49-3.68 1.28-5.29 2.31h-8.46v-19.06"/><line class="fil1 str0" x1="18.85" x2="18.85" y1="39.87" y2="39.87"/><polyline class="fil1 str0" points="0.57 23.69 37.14 23.69 37.14 42.75 28.67 42.75"/><path class="fil1 str0" d="m5.38 45.82c2.36-2.57 5.44-4.47 8.91-5.37m9.08-0.01c3.54 0.91 6.59 2.8 8.95 5.38"/><path class="fil1 str0" d="m32.21 23.69c0.02 0.28 0.03 0.57 0.03 0.86 0 4.5-2.25 8.51-5.69 10.94h-3.13c-0.66 0-1.19 0.53-1.19 1.19s0.53 1.19 1.18 1.2v4.08h-0.01c0 2.5-2.05 4.55-4.55 4.55s-4.55-2.04-4.55-4.55h-0.01v-4.82c-5.14-1.88-8.83-6.83-8.83-12.59 0-0.29 0.01-0.58 0.03-0.86h26.72z"/><path class="fil1 str0" d="m18.85 6.26c9.77 0 17.81 7.77 18.27 17.43h-36.53c0.45-9.66 8.49-17.43 18.26-17.43z"/><path class="fil1 str0" d="m8.28 23.69c0.11-4.27 1.08-8.16 2.62-11.18"/><path class="fil1 str0" d="m22.8 27.4c0 2.17-1.78 3.95-3.96 3.95-2.17 0-3.95-1.78-3.95-3.95"/><path class="fil1 str0" d="m28.03 36.65h1.34c2.85 0 5.17-2.33 5.17-5.17 0-1.87-1.03-3.54-2.48-4.43"/><rect class="fil1 str0" x="22.23" y="35.49" width="5.8" height="2.39" rx="1.19" ry="1.19"/></svg><h2 class="h2">Köszönjük!</h2>',
                buttons: [],
              });
              inputs.forEach(function (input) {
                input.value = "";
              });
              form.changed = false;
            } else {
              app.modal.open({
                title: "Valami nagyon <br>NOT GOOD!",
                class: "error",
                content: response.message,
                buttons: [
                  {
                    text: '<svg width="73px" height="72px" version="1.1" viewBox="0 0 3.9 3.85" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><rect class="svgbg" fill="none" x=".08" y=".08" width="3.74" height="3.69" rx="1.87" ry="1.85" stroke="#FEFEFE" stroke-width="0.16" stroke-miterlimit="22.9256" /><rect fill="#fefefe" x=".74" y="1.62" width="2.42" height=".6"/></svg>',
                    action: function () { },
                  },
                ],
              });
            }
          })
          .catch((err) => {
            // console.log("catch");
            // console.error(err);

            app.modal.open({
              title: "Valami nagyon <br>NOT GOOD!",
              content: err,
              class: "error",
              buttons: [
                {
                  text: '<svg width="73px" height="72px" version="1.1" viewBox="0 0 3.9 3.85" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><rect class="svgbg" fill="none" x=".08" y=".08" width="3.74" height="3.69" rx="1.87" ry="1.85" stroke="#FEFEFE" stroke-width="0.16" stroke-miterlimit="22.9256" /><rect fill="#fefefe" x=".74" y="1.62" width="2.42" height=".6"/></svg>',
                  action: function () {
                    inputs.forEach(function (input) {
                      input.disabled = false;
                    });
                    submit.disabled = false;
                  },
                },
              ],
            });
          });
        e.preventDefault();
      }
    });
  };

  app.init();
})({ token: '6dvUZv5GV5MCwbCQsoXYOapS5EXCOcRXRFRRBB1Aq607VFgup2KXtwo73WqzgUGz' });
